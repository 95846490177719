import React from 'react'
import './ProductCard.css'

const ProductCard = ({name,image,price}) => {

  const clickHandler = () => {
    window.location.pathname = "/products/"+name.replaceAll(" ","-")
  }
  return (
    <div className='productCard'>
      <img src={image} alt='' onClick={clickHandler} />
      <span className='productName' onClick={clickHandler}>{name} </span>
      <span className='productPrice'>{'₹'+price} </span>
    </div>
  )
}

export default ProductCard