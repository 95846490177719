import React from 'react'
import './Top.css'
import photo from '../images/bulk2.jpg'

const Top = () => {
  return (
    <div className='Top' style={{backgroundImage: `url(${photo})`}} >
        <img src={photo} alt='' className='banner'/>
        <span className='bannerTitle'>Aviro Bulk Clothing - Wholesale Blank Apparel</span>
    </div>
  )
}

export default Top