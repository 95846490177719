import React, {useState,useEffect} from 'react'
import './Shop.css'
import Category from './Category'
import Whitescreen from './Whitescreen'

const Shop = () => {

    const [products,setProducts] = useState([])
    const [categories, setCategories] = useState()
    const [collection, setCollection] = useState({})

    const getCats = () => {
        let arrOfCats = products.map(i => i.categories)
        let allCatsArr = []
        arrOfCats.forEach(i => {
            allCatsArr = [...allCatsArr, ...i]
        })
        let uniCats = [...(new Set(allCatsArr))]
        uniCats.forEach((i) => {
          setCollection({...collection, [i]:[]})
        })
        setCategories(uniCats)
        products.forEach((product) => {
          product.categories.forEach((category) => {
            setCollection((prev) => {
              let newColl = {...prev}
              let newVal = (newColl[category]?[...newColl[category],product]:[product])
              newColl[category] = newVal
              return newColl
            })
          })
        })
    }

    useEffect(() => {
        const getProducts = async () => {
          const response = await fetch("https://apoorvk-ecom.onrender.com/products", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body:JSON.stringify({"key": "05888001114"})
          })
    
          const result = await response.json()
          if(result){
            setProducts(result)
          }
        }
        getProducts()
      },[])

useEffect(() => {
  console.log(window.location.pathname);
  getCats()
},[products])

  return (
    <div className='shop' style={(window.location.pathname==='/'?{marginTop:'2vh'}:{marginTop:'10vh'})}>
    {categories?.length>0 ? categories.map(i => {
      return <Category key={i} name={i} products={collection[i]} />
    })
    :<Whitescreen/>}
    </div>
  )
}

export default Shop