import React from 'react'
import './Footer.css'
import email from '../images/envelope-fill.svg'
import facebook from '../images/facebook.svg'
import instagram from '../images/instagram.svg'
import telephone from '../images/telephone.svg'

const Footer = () => {
  return (
    <div className='footer'>
        <div className='socialMedia'>

            <a href='mailto:jaswant@aviro.net.in'><img src={email} alt=''/></a> 
            <a href="tel:+91-8109773942"><img src={telephone} alt=''/></a> 
            
        </div>
        <div className='copyright'>© Aviro 2023</div>
        <div className="footerLinks">
            <a href='/terms-and-conditions'>Terms of use</a>
            <a href='/coming-soon'>Privacy policy</a>
            <a href='/coming-soon'>FAQs</a>
        </div>
    </div>
  )
}

export default Footer