import React from 'react'
import './Home.css'
import Top from './Top'
import Shop from './Shop'
import ContactUs from './ContactUs'

const Home = () => {
  return (
    <div className='home'>
        <Top />
        <Shop />
        <ContactUs productName={''} />
    </div>
  )
}

export default Home