import React from 'react'
import './Description.css'
import { useEffect } from 'react'
import { useState } from 'react'
import Whitescreen from './Whitescreen'
import ContactUs from './ContactUs'

const Description = () => {
    const [product, setProduct] = useState()

    useEffect(() => {
      const getData = async () => {
        const response = await fetch("https://apoorvk-ecom.onrender.com"+ window.location.pathname, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body:JSON.stringify({"key": "05888001114"})
       })
      const result = await response.json()
      if(result){
        setProduct(result)
        }
      }
      getData()
    },[])
    
  return (
    <div style={{marginTop:"10vh"}}>
        {product? <div className='description'>
          <img src={product.imageFileData} alt=''/>
          <div className='product-data'>
            <span className='product-name'>{product.name} </span>
            <span className='product-price'>{'₹'+product.price} </span>
            <span className='product-description' >{product.description} </span>
          </div>
        </div>:<Whitescreen/>}
        {product && <ContactUs productName={product.name} />}
    </div>
  )
}

export default Description