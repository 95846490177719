import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import Comingsoon from './components/Comingsoon';
import Description from './components/Description';
import Shop from './components/Shop';
import Tnc from './components/Tnc';
import AboutUs from './components/AboutUs';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Header />
      <Routes>
        <Route exact path = "/" element = {<Home />} />
        <Route exact path = "/coming-soon" element = {<Comingsoon />} />
        <Route exact path = "/shop" element = {<Shop />} />
        <Route exact path = "/terms-and-conditions" element = {<Tnc />} />
        <Route exact path = "/about-us" element = {<AboutUs />} />
        <Route exact path = {"/products"+(window.location.pathname.replace('/products',''))} element = {<Description />} />
      </Routes>
      
      <Footer />
    </div>
    </BrowserRouter>
  );
}

export default App;
