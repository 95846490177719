import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './Header.css'
import heart from '../images/suit-heart.svg'
import cart from '../images/cart3.svg'
import list from '../images/list.svg'
import logo from '../images/logo.png'

const Header = () => {
    const [menuclicked, setMenuclicked] = useState(false)
    const navigate = useNavigate()
    const goHome = () => {
    window.location.pathname = "/"
    }

    const menuClick = () => {
        setMenuclicked((prev)=>!prev)
    }
  return (
    <div className='header'>
        <div className='title' onClick={goHome}>
            <img src={logo} alt='' />
        </div>
        <div className='title-mob' onClick={goHome}>
            <img src={logo} alt='' />
        </div>
        <div className='menu'>
            <ul>
                <li><a href='/'>Home</a></li>
                <li><a href='/shop'>Shop</a></li>
                <li><a href='/about-us'>About Us</a></li>
            </ul>
        </div>
        <div className='extra'>
        
        </div>
        <div className='extra-mob'>
            
            <button  className='menu-mob' onClick={menuClick}><img src={list} alt='' className='icon'/></button>
        </div>
        {menuclicked && <div className='menu-dropdown'>
        <span onClick = {() => setMenuclicked(false)} > X </span>
            <ul>
                <li><a href='/'>Home</a></li>
                <li><a href='/shop'>Shop</a></li>
                <li><a href='/about-us'>About Us</a></li>
            </ul>
        </div>}
        
    </div>
  )
}

export default Header