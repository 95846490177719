import React from 'react'
import './AboutUs.css'
import photo from '../images/aboutus.png'
import banner from '../images/aboutbanner.png'

const AboutUs = () => {
  return (
    <div className='AboutUs' >
    <img src={banner} alt='' style={{width:'100%'}} />
        <div className='aboutTop'>
            <img src={photo} alt='' />
            <div>
                <h1>About Us</h1>
                <p>
                Aviro is a marketplace for wholesale buying and selling across India, connecting manufacturers or big wholesalers directly to retailers. We are a bunch of technology and business people from IIMs, IITs, NITs, etc, who are working to bring efficiency in the whole distribution system and help retailers get more variety at their doorsteps. They can take advantage of our simple web interface to buy products for their retail outlets and get those without having to travel to different cities / manufacturing hubs or to buy at much higher prices from the wholesalers near them. We've put the entire wholesale buying process online to enable manufacturers & brands and retailers to drive incremental revenue, cut costs, improve their customer experience and analyze performance through data analytics.
                </p>
            </div>
        </div>
    </div>
  )
}

export default AboutUs