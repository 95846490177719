import React from 'react'
import Loader from './Loader'

const Whitescreen = () => {
  return (
    <div style={{display:'flex',justifyContent:'center',width:'100%',marginBottom:'10px'}}>
    <Loader color='black' />
    <div className='whitescreen'></div>
    </div>
  )
}

export default Whitescreen