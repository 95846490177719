import React from 'react'
import './Popup.css'

const Popup = () => {
  return (
    <div className='Popup' >
        <div className='popbox' >
            <h1>Thankyou!</h1> <br />
            <p>Your enquiry has been successfully sent. Our team will contact you soon. </p>
        </div>
    </div>
  )
}

export default Popup