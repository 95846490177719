import React from 'react'
import ProductCard from './ProductCard'
import './Category.css'

const Category = ({name,products}) => {
  return (
    <div className='category'>
        <div className='categoryName'> 
            <hr />
            <span>{name}</span> 
            <hr />
        </div>
        <div className='categorySlider'>
            {products.map(item => {
                return <ProductCard key={item.id} name={item.name} image={item.imageFileData} price={item.price} />
            })}
        </div>
    </div>
  )
}

export default Category