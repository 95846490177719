import React,{useState, useEffect} from 'react'
import './ContactUs.css'
import img from '../images/bulk1.jpg'
import Loader from './Loader'
import Popup from './Popup'

const ContactUs = ({productName}) => {
    const [product, setProduct] = useState(productName)
    const [quantity, setQuantity] = useState()
    const [message, setMessage] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [address, setAddress] = useState('')
    const [loader, setLoader] = useState(false)
    const [status, setStatus] = useState('')

    const SubmitHandler = async () => {
        setStatus('')
        setLoader(true)
        const response = await fetch("https://apoorvk-ecom.onrender.com/postMessage", {
            method : 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body : JSON.stringify({
                'userId' : '05888001114',
                'body' : {
                    'product' : product,
                    'quantity' : quantity,
                    'message' : message,
                    "sender's email" : email,
                    "contact number" : contact,
                    "address" : address,
                    "date" : new Date().toString()
                }
            })
        })
        let result = await response.json()
        if(result === 'success'){
            setLoader(false)
            setStatus('Successfully sent!')
            setProduct('')
            setQuantity('')
            setMessage('')
            setEmail('')
            setContact('')
            setAddress('')
            setTimeout(() => {
                setStatus('')
            }, 5000)
        } else {
            setLoader(false)
            setStatus('Failed to send')
        }
    }

  return (
    <div className='ContactUs' >
    {status === 'Successfully sent!' && <Popup />}
        <img src={img} alt='' />
        <div className='contactForm' >
        <span className='contactQuote' >Get A Quote!</span>
            <span>Product:</span> <input value={product} onChange={(e) => setProduct(e.target.value)} />
            <span>Quantity:</span> <input value={quantity} onChange={(e) => setQuantity(e.target.value)} /> 
            <span>Message:</span> <textarea rows='5' value={message} onChange={(e) => setMessage(e.target.value)} /> 
            <span>Your email:</span> <input value={email} onChange={(e) => setEmail(e.target.value)} /> 
            <span>Contact number:</span> <input value={contact} onChange={(e) => setContact(e.target.value)} /> 
            <span>Your Address:</span> <input value={address} onChange={(e) => setAddress(e.target.value)} /> 
            <div style={{display:'flex',alignItems:'center'}}>
            <button onClick={SubmitHandler} >Send</button>
            {loader && <Loader color="black"/>}
            </div>
        </div>
    </div>
  )
}

export default ContactUs