import React from 'react'

const Tnc = () => {
    const name = "Aviro"
  return (
    <div className='Tnc' style={{marginTop:'13vh',padding:'20px'}} >
<h1>Terms & Conditions</h1>
<p>We appreciate the trust you place when visiting our site. We guarantee to use the provided information unbiasedly and protect it from any misuse from other clients and resources. We have underlined the Terms & Conditions of our website below, which govern your use of the site and your relationship with {name}.</p>

<p>Your use of the website and services is subject to your compliance with the Terms & Conditions.</p>
<br />
<h3>General Terms & Conditions</h3>
<p>{name} is the owner and the authorized user of any trademark appearing on the website and is the copyright owner of the content, images, layout, reviews, logo, etc. posted and published on the website. We do not grant you a license to any content or material you may access to your personal, commercial use. If you fail to comply with the Terms & Conditions provided here, you may violate the copyright and other laws of India.</p>

<p>We reserve all rights to edit and modify our Terms & Conditions at any time and without any prior notice.</p>

<p>You are restricted to post any content on our website that is unlawful, defamatory, harassing, obscene, pornographic, or any material that may encourage misconduct will be viewed as a criminal offence.</p>
<br />
<h3>Breach of Terms & Conditions</h3>
<p>If you are found not complying with the conditions, terms, notices and agreements mentioned above, the rights granted to you will end with immediate effect. The immediate termination of the material that you control or possess will be followed without giving any prior notice.</p>

<p>You agree to the policy that you will make the best efforts to protect {name} as well as subsidiaries, affiliates, business partners, employees, licensors, agents, joint venture and third-party information providers.</p>
<br />
<h3>Disclaimer</h3>
<p>{name} reserves the rights to revise its policy disclaimers at any time. Thus, we don’t guarantee non-infringement. If you are using our website and services after updated changes without considering them and face any sort of damage by downloading content or using information, then we won’t be liable for it. Your decision to continue using the services will be considered that you agree to the applied modifications.</p>
    </div>
  )
}

export default Tnc